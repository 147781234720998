import { bookActions } from '@/actions';
import BottomCard from '@/components/elements/Card/Card';
import { Card } from '@/components/elements/cards';
import { LinkButton } from '@/components/elements/forms/buttons';
import Header from '@/components/elements/Header/Header';
import { getTotalPrice, shouldShowFrom, showPrices } from '@/helpers';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { BookState } from '@/types/state/book';
import { PlaceEmployee } from '@/types/state/shared';
import { Fragment, useContext, useMemo } from 'react';
import Modal from '../../redesign/Modal/Modal';
import { getPickedEmployee, getRecommendedServices, ScrollToTopElement } from '../BookAppointment.helpers';
import { BookAppointmentContext, useBookAppointmentContext } from '../BookAppointment.hooks';
import ServiceItem from '../shared/ServiceItem';
import Summary, { SummaryProps } from '../shared/Summary';

const baseTranslationKey = 'components.modules.modals.BookAppointment.views.SummaryView';

const SummaryView = () => {
  const { isMobileView } = useMobileView();
  const dispatch = useAppDispatch();
  const context = useBookAppointmentContext();
  const { employee, place, services: pickedServices, time } = useAppSelector((state) => state.book) as BookState;

  const recommendedServices = useMemo(() => getRecommendedServices(pickedServices), [pickedServices]);
  const pickedEmployee: PlaceEmployee | undefined = (() => {
    if (place.about?.settings?.hideEmployees) {
      return undefined;
    }

    const picked = getPickedEmployee(employee, place.employees);
    return picked ?? { id: 0, about: { name: _s('anyEmployee'), jobTitle: _s('availableAllEmployees') } };
  })();

  const [pickedAddonServices, pickedMainServices] = [
    pickedServices.filter((service) => service?.about?.settings?.isAddOn),
    pickedServices.filter((service) => !service?.about?.settings?.isAddOn),
  ];

  const canPickRecommendedServices = recommendedServices.length > 0 && pickedMainServices.length <= 1;

  const summaryProps: SummaryProps = {
    pickedEmployee,
    pickedServices,
    showAddAdditionalService: !pickedAddonServices.length,
    onRemoveService: handleRemoveService,
    onRemoveEmployee: handleRemoveEmployee,
    onShowAvailableServices: handleShowAvailableServices,
  };

  function handleShowAvailableServices() {
    context.updateView('pickServices');
  }

  function handleRemoveService(serviceId: number) {
    // @ts-ignore
    dispatch(bookActions.popService(serviceId, time?.timestamp, pickedServices, employee, place, true));
  }

  function handleRemoveEmployee() {
    dispatch(bookActions.removeEmployee());
    context.updateView('pickEmployee');
  }

  function handleGoBack() {
    if (pickedEmployee) {
      context.updateView('pickEmployee');
      return;
    }

    context.view.previous ? context.updateView(context.view.previous) : context.close();
  }

  return (
    <Modal.Content floating={!isMobileView} gradientBackground>
      <ScrollToTopElement />
      <Modal.Header title={_s(`${baseTranslationKey}.title`)} onBack={handleGoBack} />
      <div className={`flex flex-col ${isMobileView ? 'px-lg' : ''}`}>
        {!isMobileView && (
          <div className="px-md">
            <Summary {...summaryProps} />
          </div>
        )}
        {isMobileView && (
          <Card className="p-lg !m-0 rounded-lg !border-0 bg-white">
            <Summary {...summaryProps} />
          </Card>
        )}
        {canPickRecommendedServices && (
          <>
            <div className="pt-xxl">
              <Header label={_s(`${baseTranslationKey}.addons.header`)} size="md" />
            </div>
            {recommendedServices.map((service, idx) => (
              <ServiceItem
                key={service.id}
                service={service}
                isRecommended={true}
                underline={recommendedServices.length - 1 !== idx}
              />
            ))}
          </>
        )}
        {!isMobileView && (
          <div className="pt-xxl flex flex-col">
            <Footer />
          </div>
        )}
      </div>
      {isMobileView && (
        <Fragment>
          {/* spacer to avoid elements going under fixed bottom card */}
          <div className="h-[128px]" />
          {/* spacer to avoid elements going under fixed bottom card */}
          <div className="fixed bottom-0 left-0 right-0 mt-auto">
            <BottomCard className="pb-lg h-auto w-full bg-white">
              <Footer />
            </BottomCard>
          </div>
        </Fragment>
      )}
    </Modal.Content>
  );
};

const Footer = () => {
  const props = useContext(BookAppointmentContext);
  const { employee, place, services: pickedServices, campaigns } = useAppSelector((state) => state.book) as BookState;

  const pickedEmployee = getPickedEmployee(employee, place.employees);
  const serviceCountText = _s(`${baseTranslationKey}.cta.description`, { count: pickedServices.length });
  const priceListId = pickedEmployee?.about?.priceListId ?? 0;
  const total = getTotalPrice(pickedServices, place, priceListId, {}, 0, 1, true, campaigns);
  const totalWithoutCampaigns = getTotalPrice(pickedServices, place, priceListId);
  const hasCampaignPrice = total !== totalWithoutCampaigns;
  const isPriceVariable =
    shouldShowFrom(pickedServices, place) && total !== _s('variablePrice') && total !== _s('freePrice');
  const shouldShowPrices = showPrices(place, pickedServices);
  const shouldShowCTA = pickedServices.length > 0;
  const calendarPathname = (() => {
    if (!shouldShowCTA) return '';
    return `/boka-tjanst/${place.about.slug ? place.about.slug + '-' : ''}${place.id}/${pickedServices[0].about.slug}-${
      pickedServices[0].id
    } `;
  })();

  return (
    <Fragment>
      {shouldShowPrices && (
        <div className="pb-sm flex items-center justify-between">
          <span className="text-black-900 text-xs font-semibold">{serviceCountText}</span>
          <span className="text-black-900 text-xs font-semibold">
            {isPriceVariable ? `${_s('from')} ` : ''}
            {hasCampaignPrice ? (
              <span className="text-highlight-700">
                {total} <span className="text-black-600 line-through">({totalWithoutCampaigns})</span>
              </span>
            ) : (
              total
            )}
          </span>
        </div>
      )}
      <LinkButton onClick={props.close} variant="primary" size="md" to={calendarPathname}>
        {_s(`${baseTranslationKey}.cta.label`)}
      </LinkButton>
    </Fragment>
  );
};

export default SummaryView;
