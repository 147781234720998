import Tag, {
  CertificateTag,
  GiftCardTag,
  KlarnaTag,
  QliroTag,
  RocketTag,
  WellnessCardTag,
} from '@/components/elements/Tag';
import { getImageSize, isSistaminuten } from '@/helpers';
import { _s } from '@/locale';
import { SearchPlace } from '@/types/api/services/search';

type CardTagsProps = {
  place: SearchPlace;
  source?: string;
};

const CardTags = ({ place, source }: CardTagsProps) => {
  const { about, topSearch, distance } = place;
  const { associations } = about;

  const hasDiscount =
    !isSistaminuten() && !topSearch && about.settings && about.settings.topOfSearch && source === 'serp-page-top';
  const hasRocket = !isSistaminuten() && topSearch;
  const hasCampaign = !isSistaminuten() && about?.settings?.hasCampaigns;
  const hasSistaminutenDiscount = isSistaminuten() && about?.sistaminutenDiscount > 0;
  const hasKlarna = !isSistaminuten() && about?.settings?.hasKlarna;
  const hasQliro = !isSistaminuten() && about?.settings?.hasQliro;
  const hasCertificate = !isSistaminuten() && about?.settings?.displayCertificates;
  const hasGiftCard = !isSistaminuten() && about?.settings?.sellsGiftCard;
  const hasWellnessCard = !isSistaminuten() && about?.settings?.wellness;

  if (
    hasCampaign ||
    hasRocket ||
    hasDiscount ||
    hasSistaminutenDiscount ||
    hasKlarna ||
    hasQliro ||
    hasCertificate ||
    hasGiftCard ||
    hasWellnessCard ||
    associations?.length ||
    distance
  ) {
    return (
      <div className="flex w-full items-end justify-between">
        <div className="flex w-full flex-wrap items-center justify-start gap-2">
          {hasDiscount && <Tag variant="discount">{_s('20discount')}%</Tag>}
          {hasRocket && <RocketTag />}
          {hasCampaign && <Tag variant="campaign">{_s('campaignOffer')}</Tag>}
          {hasSistaminutenDiscount && (
            <Tag variant="campaign">
              <span className="w-12 text-center">{'-' + about.sistaminutenDiscount + '%'}</span>
            </Tag>
          )}
          {hasKlarna && <KlarnaTag />}
          {hasQliro && <QliroTag />}
          {hasCertificate && <CertificateTag />}
          {hasGiftCard && <GiftCardTag />}
          {hasWellnessCard && <WellnessCardTag />}
          {associations && (
            <div className="flex w-max gap-x-2">
              {associations.map((association, key) => {
                const imageSource = getImageSize(association.imgSrc, 'x24');
                return (
                  <img
                    key={key}
                    src={imageSource}
                    className="!h-[19px] max-h-[19px] w-auto"
                    width="19"
                    height="19"
                    alt={association.description}
                    data-tip={association.description}
                    data-place={'top'}
                    onClick={(e) => e.preventDefault()}
                  />
                );
              })}
            </div>
          )}
        </div>
        {distance && (
          <span className="text-black-600 ml-auto self-start whitespace-nowrap pl-4 text-xs font-semibold">
            {distance}
          </span>
        )}
      </div>
    );
  }
  return null;
};

export default CardTags;
