import { z } from 'zod';
import { clientBundleSchema } from '../api/services/users/schema';
import { placeSchema, placeServiceSchema, placeServicesSchema, sistaminutenCampaignSchema } from './shared';

export const bookDateSchema = z.object({
  dynamicPriceListIdKey: z.string().optional(),
  employees: z.any().optional(), // <- fix this
  selected: z.number().or(z.string()).optional(),
  timestamp: z.number().optional(),
  selectedCapacity: z.nullable(z.number()).default(1),
  capacity: z.number().nullable().optional(),
});

export const giftcardSchema = z.object({
  giftCardType: z
    .union([
      z.literal('UGC'),
      z.literal('EGC'),
      z.literal('IGC'),
      z.literal('VCIGC'),
      z.literal('VCEGC'),
      z.literal('UGCW'),
    ])
    .optional(),
  giftCardExpiryDate: z.string().optional(),
  amount: z.number().optional(),
  code: z.string().optional(),
  formId: z.number().optional(),
  ownerCompanyId: z.number().or(z.string()).optional().nullable(),
});

export type Giftcard = z.infer<typeof giftcardSchema>;

export const giftcardsSchema = z.record(giftcardSchema);

export type Giftcards = z.infer<typeof giftcardsSchema>;

export const bookUUIDSchema = z.nullable(
  z.object({
    giftcards: giftcardsSchema.optional().nullable(),
    hash: z.string().optional(),
    uuid: z.string(),
  }),
);

const bookEmployeeSchema = z.number();

const campaignServiceSchema = z.object({
  discountPercent: z.number().optional().nullable(),
  discountPrice: z.number().optional().nullable(),
  id: z.number().optional().nullable(),
});

export const bookCampaignsSchema = z.object({
  campaignId: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable(),
  endDate: z.number().optional().nullable(),
  id: z.number().optional().nullable(),
  name: z.string().optional().nullable(),
  place: z.number().optional().nullable(),
  services: z.array(campaignServiceSchema).optional().nullable(),
  startDate: z.number().optional().nullable(),
  status: z.number().optional().nullable(),
  type: z.number().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
});

export type BookCampaign = z.infer<typeof bookCampaignsSchema>;

export const bookPlaceServiceSchema = z.object({
  id: z.number(),
  name: z.string(),
  order: z.number(),
  own: z.boolean(),
  services: z.array(placeServiceSchema),
});

export type BookPlaceService = z.infer<typeof bookPlaceServiceSchema>;

export const bookPlaceSchema = placeSchema.merge(
  z.object({
    services: z.array(bookPlaceServiceSchema),
  }),
);

export type BookPlace = z.infer<typeof bookPlaceSchema>;

export const bookStateSchema = z.object({
  availability: z.any().optional(), // <- fix this
  campaigns: z.array(bookCampaignsSchema.or(sistaminutenCampaignSchema)).optional().nullable(),
  employee: bookEmployeeSchema.optional().nullable(),
  place: bookPlaceSchema,
  services: placeServicesSchema,
  time: bookDateSchema,
  usageReqId: bookUUIDSchema.optional().nullable(),
  fromSsr: z.boolean().optional(),
  saved: z.boolean().optional(),
  saving: z.boolean().optional(),
  failure: z.string().optional(),
  errorGiftCard: z.string().optional(),
  successGiftCard: z.boolean().optional(),
  validateGiftCard: z.boolean().optional(),
  keepEmployee: z.boolean().optional(),
  priceId: z.string().optional(),
  appliedBundle: clientBundleSchema.optional(),
  changeTimeBookingId: z.number().optional(),
});
export type BookState = z.infer<typeof bookStateSchema>;
